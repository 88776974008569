<template>
    <div class="w-full h-full flex flex-col">
        
        
        <div 
            v-if="isMobile"
            class="w-full h-full overflow-x-hidden overflow-y-auto">
            
            <div 
                class=""
                style="
                    background: radial-gradient(circle, rgba(30,50,73,1) 0%, rgba(29,36,44,1) 70%);
                ">
                
                <div class="flex items-center justify-center h-12 w-full">
                    <h1 class="text-white">
                        {{$t('dashboard.currently')}} 
                    </h1>
                </div>
            
                <div 
                    v-if="showCurrent"
                    class="text-cyan w-full flex items-start justify-center">
                    
                    <div class="my-12 overflow-hidden">
                        <div class="flex">
                            <div 
                                class="flex-1 flex items-center"
                                @click="currentOffers = offers.filter(o => o.status == 'active'); scrollToOffersList();">
                                
                                <strong class="text-white text-4xl font-bold mr-2">
                                    {{offers.filter(o => o.status == 'active').length}}
                                </strong>
                                <div 
                                    class="leading-tight opacity-75"
                                    style="max-width: 5rem;"
                                    v-html="$t('offers.activeOffers')"
                                />
                            </div>
                            
                            <div 
                                class="flex-1 flex items-center"
                                @click="currentOffers = offersPassedFollowupDate; scrollToOffersList();">
                                
                                <strong class="text-white text-4xl font-bold mr-2">
                                    {{offersPassedFollowupDate.length}}
                                </strong>
                                <span 
                                    class="leading-tight opacity-75"
                                    style="max-width: 5rem;"
                                    v-html="$t('offers.passedFollowUp')"
                                />
                            </div>
                            <div 
                                v-if="offersPassedHibernation.length"
                                class="flex-1 flex items-center"
                                @click="currentOffers = offersPassedHibernation; scrollToOffersList();">
                                
                                <strong class="text-white text-4xl font-bold mr-2">
                                    {{offersPassedHibernation.length}}
                                </strong>
                                <span 
                                    class="leading-tight opacity-75"
                                    style="max-width: 5rem;"
                                    v-html="$t('offers.wentIntoHibernation')"
                                />
                            </div>
                        </div>
                        <div class="text-center mb-4">
                            <hr class="border-t border-dashed border-blue-400 opacity-75 mb-4" />
                            
                            <strong class="text-4xl text-white font-bold block leading-none">
                                {{ offerActiveAmount }}
                            </strong>
                            <span class="opacity-75">
                                {{$t('offers.totalInActiveOffers')}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            
            <transition
                v-if="showCurrent && messagesSorted.length > 0"
                appear
                name="slide-up" >
                
                <div 
                    class="panel m-4 flex flex-col items-center"
                    :class="messagesSorted.length > 0 ? 'notice' : 'bg-white'">
                    
                    <div class="flex-grow flex w-full">
                        <div class="flex-grow">
                            <h2 class="text-black">{{$t('dashboard.newMessages')}}</h2>
                            <p v-if="messagesSorted.length > 0">
                                {{$tc('dashboard.unreadMessagesText', messagesSorted.length)}}
                                {{$t('dashboard.hasNotBeenMarkedAsRead')}}
                                {{$tc('dashboard.uniqueOffersWithMessagesText', uniqueOffersWithMessages.length)}}
                            </p>
                            <p v-else>
                                {{$t('dashboard.noUnreadMessages')}}
                            </p>
                        </div>
                        <img 
                            class="flex-none object-contain ml-8 w-24 h-24"
                            src="/assets/illustrations/undraw_Newsletter_re_wrob.svg" 
                        />
                    </div>
                    
                    <button 
                        class="button submit flex-none"
                        v-if="messagesSorted.length > 0"
                        @click="uniqueOffersWithMessages.length ? currentOffers = uniqueOffersWithMessages : null; scrollToOffersList();">
                        
                        {{uniqueOffersWithMessages.length > 1 ? $t('offers.listOffers') : $t('offers.viewOffer')}}
                    </button>
                </div>
            </transition>
            
            <div 
                id="offersList"
                v-if="currentOffers"
                class="p-4 pt-16 relative bg-gray-300">
                
                <div 
                    class="close text-gray hover:text-black absolute top-0 right-0 m-4 bg-white w-8 h-8 rounded-full flex items-center justify-center" 
                    @click="currentOffers = null">
                    
                    <i class="fas fa-times"></i>
                </div>
                
                <transition-group 
                    appear
                    name="slide-up" 
                    tag="div"
                    class="grid grid-cols-1 gap-4">
                    
                    <OfferCard 
                        v-for="(offer) in currentOffers"
                        :key="offer.offerId"
                        :offer="offer"
                        class="slide-up-item"
                        @view="viewOfferLink(offer)"
                    />
                </transition-group>
            </div>
            
            <div 
                v-if="showDateRange"
                class="m-4">
            
                <transition-group 
                    appear
                    name="slide-up" 
                    tag="div"
                    class="grid gap-4">
                    
                    <div 
                        :key="'history_1'" 
                        class="slide-up-item ">
                        
                        <!-- <h2 class="text-black text-center">History</h2> -->
                        
                        <div class="flex text-sm mb-4 bg-white rounded-lg overflow-hidden">
                            <a 
                                @click="selectDateRangePreset('last7days')"
                                class="py-2 flex-grow text-center border-r"
                                :class="preset == 'last7days' ? 'link' : null">
                                
                                {{$t('dashboard.last7days')}}
                            </a>
                            <a 
                                @click="selectDateRangePreset('lastTwoWeeks')"
                                class="py-2 flex-grow text-center border-r"
                                :class="preset == 'lastTwoWeeks' ? 'link' : null">
                                
                                {{$t('dashboard.lastTwoWeeks')}}
                            </a>
                            <a 
                                @click="selectDateRangePreset('last30days')"
                                class="py-2 flex-grow text-center border-r"
                                :class="preset == 'last30days' ? 'link' : null">
                                
                                {{$t('dashboard.last30days')}}
                            </a>
                            <a 
                                @click="selectDateRangePreset('last3Months')"
                                class="py-2 flex-grow text-center border-r"
                                :class="preset == 'last3Months' ? 'link' : null">
                                
                                {{$t('dashboard.last3Months')}}
                            </a>
                            <div 
                                @click="showCalendar = !showCalendar; preset = null"
                                class="py-2 flex-grow text-center"
                                :class="showCalendar ? 'link' : null">
                                
                                <i class="far fa-calendar-alt" />
                            </div>
                        </div>
                        
                        <div 
                            v-show="showCalendar" 
                            class="fixed inset-0 p-8 bg-white flex items-center justify-center"
                            style="z-index: 2000">
                            
                            <DatePicker 
                                range 
                                v-model="dateRange" 
                                :clearable="false"
                                :open="true"
                                :inline="true"
                                class="cursor-pointer" 
                            />
                            
                            <div 
                                class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
                                @click="showCalendar = false">
                                <i class="fas fa-times" />
                            </div>
                        </div>
                        
                        <h3 class="text-center">{{ $moment( startDate ).format('LL') +' '+ $t('general.to')+' '+  $moment( endDate ).format('LL') }}</h3>
                    </div>
                    
                    <div :key="'history_2'" class="slide-up-item panel flex flex-col">
                        <div class="flex flex-grow">
                            <div class="flex-grow">
                                <h2 class="text-black">
                                    {{$t('dashboard.acceptedAndDeclined')}}
                                </h2>
                                
                                <div class="flex-none flex items-center">
                                    <div class="flex-none flex items-center mr-4">
                                        <strong class="text-clay text-4xl font-bold mr-2 leading-none">
                                            {{offersAccepted.length}}
                                        </strong>
                                        <span 
                                            class="leading-tight opacity-75" 
                                            style="max-width: 5rem;"
                                            v-html="$tc('dashboard.acceptedOffersText', offersAccepted.length)"
                                        />
                                    </div>
                                    <div class="flex-none flex items-center">
                                        <strong class="text-clay text-4xl font-bold mr-2 leading-none">
                                            {{offersDeclined.length}}
                                        </strong>
                                        <span 
                                            class="leading-tight opacity-75"
                                            style="max-width: 5rem;"
                                            v-html="$tc('dashboard.declinedOffersText', offersDeclined.length)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <img 
                                src="/assets/illustrations/undraw_having_fun_iais.svg" 
                                class="flex-none object-contain ml-8 w-24 h-24"
                            />
                        </div>
                    </div>
                    
                    <div :key="'history_3'" class="slide-up-item panel flex flex-col">
                        <div class="flex flex-grow">
                            <div class="flex-grow">
                                <h2 class="text-black">
                                    {{$t('dashboard.amountAccepted')}}
                                </h2>
                                <div class="flex-none flex items-center">
                                    <div class="flex-none flex items-center mr-8">
                                        <strong class="text-clay text-4xl font-bold mr-2 leading-none">
                                            {{ offerAcceptedAmount }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <img 
                                src="/assets/illustrations/undraw_pay_online_b1hk.svg" 
                                class="flex-none object-contain ml-8 w-24 h-24"
                            />
                        </div>
                    </div>
                    
                    <div :key="'history_4'" class="slide-up-item panel">
                        <p class="opacity-75 mb-0">
                            {{$t('dashboard.offersPerDayInCurrentRange')}}
                        </p>
                        <div class="-mx-4">
                            <vue-frappe
                                id="chart-status"
                                :key="'chartStatus:'+startDate+':'+endDate"
                                :type="'bar'"
                                :labels="chartStatus.labels"
                                :height="200"
                                :colors="chartStatus.colors"
                                :dataSets="chartStatus.datasets" 
                                :tooltipOptions="{
                                    formatTooltipX: d => d,
                                    formatTooltipY: d => d + ' offers'
                                }"
                                :barOptions="{
                                    stacked: true,
                                    spaceRatio: 0.5,
                                }"
                                :axisOptions="{
                                    xAxisMode: 'tick',
                                    xIsSeries: true,
                                }"
                            />
                        </div>
                    </div>
                </transition-group>
                
                <div class="panel mt-4">
                    <h2 class="text-black">
                        {{$t('dashboard.latestOfferTrackings')}}
                    </h2>
                    
                    <div>
                        <p class="opacity-75">
                            {{$t('dashboard.durationOfVisitsPerDayInCurrentRange')}}
                        </p>
                        
                        <div class="-mx-4">
                            <vue-frappe
                                id="chart-activity"
                                :key="'chartStatus:'+startDate+':'+endDate"
                                :type="'line'"
                                :labels="chartActivity.labels"
                                :height="200"
                                :colors="chartActivity.colors"
                                :lineOptions="{
                                    hideDots: 1,
                                    regionFill: 1,
                                    spline: 0,
                                }"
                                :dataSets="chartActivity.datasets" 
                                :axisOptions="{
                                    xAxisMode: 'tick' // default: 'span'
                                }"
                                :tooltipOptions="{
                                    formatTooltipX: d => d,
                                    formatTooltipY: d => $tc('general.minutes', d),
                                }"
                            />
                        </div>
                    </div>
                    
                    <div
                        @click="viewOfferLink(track.offer)"
                        class="py-3 mb-2 flex hover:text-black cursor-pointer"
                        v-for="track in trackingsFiltered"
                        :key="track.trackingId">
                        
                        <div class="flex-none mr-8 relative w-8">
                            <div class="border-r absolute top-0 bottom-0 w-4 -mb-8" />
                            
                            <i 
                                class="absolute top-0 fas w-8 h-8 text-base bg-gray-300 text-white rounded-full flex items-center justify-center"
                                :class="
                                    [
                                        track.device == 'mobile' ? 'fa-mobile-alt' : '',
                                        track.device == 'tablet' ? 'fa-tablet-alt' : '',
                                        track.device == 'desktop' || !track.device ? 'fa-desktop' : ''
                                    ]
                                "
                            />
                        </div>
                        <div class="flex-grow text-sm leading-tight">
                            <div class="flex mb-2">
                                <div class="w-24">{{$t('dashboard.trackings.customer')}}</div>
                                <div class="flex-grow">{{track.offer.contact && track.offer.contact.name ? track.offer.contact.name : ''}}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="w-24">{{$t('dashboard.trackings.totalPrice')}}</div>
                                <div class="flex-grow">{{currencyFormat(track.offer.priceTotal)}}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="w-24">{{$t('dashboard.trackings.duration')}}</div>
                                <div class="flex-grow">{{ (track.visibleSeconds *1000) || 0 | duration('humanize') }}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="w-24">{{$t('dashboard.trackings.when')}}</div>
                                <div class="flex-grow">{{ track.updatedAt | moment('lll') }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex w-full items-center justify-center">
                        <div class="rounded-full w-8 h-8 text-center border border-gray-400 flex items-start justify-center text-black cursor-pointer" @click="loadMoreTracking()">...</div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div 
            v-else
            class="h-full overflow-auto w-full relative">
            
            <div 
                v-if="showCurrent"
                class="px-16">
                
                <h1 class="py-8 h-32">{{$t('dashboard.dashboard')}}</h1>
                
                <transition-group 
                    appear
                    name="slide-up" 
                    tag="div"
                    class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-16">
                    
                    <div 
                        class="slide-up-item panel bg-gradient-to-t from-clay-dark to-clay-lighter text-cyan flex flex-col "
                        :key="1">
                        
                        <div class="flex flex-grow">
                            <div class="flex-grow">
                                <h2 class="text-white mb-4 capitalize">
                                    {{$t('offers.activeOffers')}}
                                </h2>
                                <p class="opacity-75">
                                    {{$tc('dashboard.activeOffersText', offers.filter(o => o.status == 'active').length)}} 
                                    <span v-if="offersPassedFollowupDate.length || offersPassedHibernation.length">
                                        <span v-if="offersPassedFollowupDate.length"> {{offersPassedFollowupDate.length}} {{$t('offers.passedFollowUp')}}</span>.
                                        <span v-if="offersPassedHibernation.length"> {{offersPassedHibernation.length}} {{$t('offers.wentIntoHibernation')}}</span>.
                                    </span>
                                </p>
                            </div>
                            <img 
                                src="/assets/illustrations/undraw_browsing_online_sr8c.svg" 
                                class="flex-none object-contain ml-8 w-32 h-32"
                            />
                        </div>
                        
                        <div class="flex-none flex items-center -ml-4">
                            <div 
                                class="flex items-center hover:bg-clay-light rounded-lg px-4 cursor-pointer"
                                @click="currentOffers = offers.filter(o => o.status == 'active'); scrollToOffersList();">
                                
                                <strong class="text-white text-4xl font-bold mr-3">
                                    {{offers.filter(o => o.status == 'active').length}}
                                </strong>
                                <span 
                                    class="leading-tight opacity-75" 
                                    style="max-width: 5rem;"
                                    v-html="$t('offers.activeOffers')"
                                />
                            </div>
                            <div 
                                class="flex items-center  hover:bg-clay-light rounded-lg px-4 cursor-pointer"
                                @click="currentOffers = offersPassedFollowupDate; scrollToOffersList();">
                                
                                <strong class="text-white text-4xl text-4xl font-bold mr-3">
                                    {{offersPassedFollowupDate.length}}
                                </strong>
                                <span 
                                    class="leading-tight opacity-75" 
                                    style="max-width: 5rem;"
                                    v-html="$t('offers.passedFollowUp')"
                                />
                            </div>
                            <div 
                                v-if="offersPassedHibernation.length "
                                class="flex items-center hover:bg-clay-light rounded-lg px-4 cursor-pointer"
                                @click="currentOffers = offersPassedHibernation; scrollToOffersList();">
                                
                                <strong class="text-white text-4xl text-4xl font-bold mr-3">
                                    {{offersPassedHibernation.length}}
                                </strong>
                                <span 
                                    class="leading-tight opacity-75" 
                                    style="max-width: 5rem;"
                                    v-html="$t('offers.wentIntoHibernation')"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div 
                        class="slide-up-item panel bg-gradient-to-t from-clay-dark to-clay-lighter text-cyan flex flex-col"
                        :key="2">
                        
                        <div class="flex flex-grow">
                            <div class="flex-grow">
                                <h2 class="text-white mb-4">
                                    {{$t('offers.totalInActiveOffers')}}
                                </h2>
                                <p class="opacity-75">
                                    <!-- In the {{offers.filter(o => o.status == 'active').length}} offers that are active, the total amount in {{currencies.find( c => c.code == account.locale.currency).name}} is currently  -->
                                    
                                    {{
                                        $t('dashboard.totalInActiveOffersText', {
                                            activeCount: offers.filter(o => o.status == 'active').length,
                                            currencyName: currencies.find( c => c.code == account.locale.currency).name, 
                                        }) 
                                    }}
                                </p>
                            </div>
                            <img 
                                src="/assets/illustrations/undraw_pay_online_b1hk.svg" 
                                class="flex-none object-contain ml-8 w-32 h-32"
                            />
                        </div>
                        
                        <div class="flex-none flex items-center">
                            <div class="flex-none flex items-center mr-8">
                                <strong class="text-white text-4xl font-bold mr-3">
                                    {{ offerActiveAmount }}
                                </strong>
                            </div>
                        </div>
                    </div>
                    
                    <div 
                        class="slide-up-item panel flex flex-col"
                        :class="messagesSorted.length > 0 ? 'notice' : 'bg-white'"
                        :key="3">
                        
                        <div class="flex flex-grow">
                            <div class="flex-grow">
                                <h2 class="text-black mb-4">
                                    {{$t('dashboard.newMessages')}}
                                </h2>
                                <p v-if="messagesSorted.length > 0">
                                    {{$tc('dashboard.unreadMessagesText', messagesSorted.length)}}
                                    {{$t('dashboard.hasNotBeenMarkedAsRead')}}
                                    {{$tc('dashboard.uniqueOffersWithMessagesText', uniqueOffersWithMessages.length)}}
                                </p>
                                <p v-else>
                                    {{$t('dashboard.noUnreadMessages')}}
                                </p>
                            </div>
                            <img 
                                src="/assets/illustrations/undraw_Newsletter_re_wrob.svg" 
                                class="flex-none object-contain ml-8 w-32 h-32"
                            />
                        </div>
                        
                        <div class="flex items-center -ml-4 ">
                            <div 
                                class="flex-none flex items-center rounded-lg px-4 "
                                :class="messagesSorted.length > 0 ? 'hover:bg-orange-600 hover:text-white cursor-pointer' : null"
                                @click="uniqueOffersWithMessages.length ? currentOffers = uniqueOffersWithMessages : null; scrollToOffersList();">
                                
                                <strong 
                                    class="text-4xl font-bold mr-3">
                                    {{ messagesSorted.length}}
                                </strong>
                                <span 
                                    class="leading-tight opacity-75"
                                    style="max-width: 5rem;">
                                    
                                    {{$t('dashboard.unreadMessages')}}
                                </span>
                            </div>  
                        </div>
                    </div>
                </transition-group>
            </div>
            <div 
                id="offersList"
                v-if="currentOffers"
                class="mt-16 p-16 relative bg-gray-300">
                
                <div 
                    class="close text-gray hover:text-black absolute top-0 right-0 m-4 bg-white w-8 h-8 rounded-full flex items-center justify-center" 
                    @click="currentOffers = null">
                    <i class="fas fa-times"></i>
                </div>
                
                <transition-group 
                    appear
                    name="slide-up" 
                    tag="div"
                    class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-16">
                    
                    <OfferCard 
                        v-for="(offer) in currentOffers"
                        :key="offer.offerId"
                        :offer="offer"
                        class="slide-up-item"
                        @view="viewOfferLink(offer)"
                    />
                </transition-group>
            </div>
            <div 
                v-if="showDateRange"
                class="p-16">
                
                <transition-group 
                    appear
                    name="slide-up" 
                    tag="div"
                    v-if="
                        trackings &&
                        startDate && 
                        endDate && 
                        chartActivity && 
                        chartActivity.datasets &&
                        chartStatus && 
                        chartStatus.datasets
                    "
                    class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-16">
                    
                    
                    <div
                        class="slide-up-item col-span-2 "
                        :key="5">
                        
                        <div class="mb-16">
                            <div>
                                <h2 class="mb-4">
                                    {{$t('dashboard.history')}}
                                    <span class="opacity-75">
                                        {{ $moment( startDate ).format('LL') +' '+ $t('general.to')+' '+  $moment( endDate ).format('LL') }}
                                    </span>
                                </h2>
                                
                                <div class="flex text-sm mb-4 bg-white rounded-lg overflow-hidden">
                                    <a 
                                        @click="selectDateRangePreset('last7days')"
                                        class="py-2 flex-grow text-center border-r"
                                        :class="preset == 'last7days' ? 'link' : null">
                                        
                                        {{$t('dashboard.last7days')}}
                                    </a>
                                    <a 
                                        @click="selectDateRangePreset('lastTwoWeeks')"
                                        class="py-2 flex-grow text-center border-r"
                                        :class="preset == 'lastTwoWeeks' ? 'link' : null">
                                        
                                        {{$t('dashboard.lastTwoWeeks')}}
                                    </a>
                                    <a 
                                        @click="selectDateRangePreset('last30days')"
                                        class="py-2 flex-grow text-center border-r"
                                        :class="preset == 'last30days' ? 'link' : null">
                                        
                                        {{$t('dashboard.last30days')}}
                                    </a>
                                    <a 
                                        @click="selectDateRangePreset('last3Months')"
                                        class="py-2 flex-grow text-center border-r"
                                        :class="preset == 'last3Months' ? 'link' : null">
                                        
                                        {{$t('dashboard.last3Months')}}
                                    </a>
                                    <div 
                                        @click="showCalendar = !showCalendar; preset = null"
                                        class="py-2 flex-grow text-center"
                                        :class="showCalendar ? 'link' : null">
                                        
                                        <i class="far fa-calendar-alt" />
                                    </div>
                                </div>
                                
                                <div 
                                    v-if="showCalendar"
                                    class="flex items-center justify-center">
                                    
                                    <DatePicker 
                                        range 
                                        v-model="dateRange" 
                                        :clearable="false"
                                        :open="true"
                                        :inline="true"
                                        class="cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div class="grid gap-16 auto-rows-min">
                            <div class="grid gap-16 lg:grid-cols-2">
                                
                                <div class="panel flex flex-col">
                                    <div class="flex flex-grow">
                                        <div class="flex-grow">
                                            <h2 class="text-black mb-4">{{$t('dashboard.acceptedAndDeclined')}}</h2>
                                            <p>
                                                {{$tc('dashboard.activeOffersText', offers.filter(o => o.status == 'active').length)}} 
                                                <span v-if="offersPassedFollowupDate.length || offersPassedHibernation.length">
                                                    <span v-if="offersPassedFollowupDate.length">{{offersPassedFollowupDate.length}} {{$t('offers.passedFollowUp')}}</span>
                                                    <span v-if="offersPassedHibernation.length"> and {{offersPassedHibernation.length}} {{$t('offers.wentIntoHibernation')}}</span>.
                                                </span>
                                            </p>
                                        </div>
                                        <img 
                                            src="/assets/illustrations/undraw_having_fun_iais.svg" 
                                            class="flex-none object-contain ml-8 w-32 h-32"
                                        />
                                    </div>
                                    
                                    <div class="flex-none flex items-center">
                                        <div class="flex-none flex items-center mr-8">
                                            <strong class="text-clay text-4xl font-bold mr-2 leading-none">
                                                {{offersAccepted.length}}
                                            </strong>
                                            <span 
                                                class="leading-tight opacity-75" 
                                                style="max-width: 5rem;"
                                                v-html="$tc('dashboard.acceptedOffersText', offersAccepted.length)"
                                            />
                                        </div>
                                        <div class="flex-none flex items-center">
                                            <strong class="text-clay text-4xl font-bold mr-2 leading-none">
                                                {{offersDeclined.length}}
                                            </strong>
                                            <span 
                                                class="leading-tight opacity-75"
                                                style="max-width: 5rem;"
                                                v-html="$tc('dashboard.declinedOffersText', offersDeclined.length)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="panel flex flex-col">
                                    <div class="flex flex-grow">
                                        <div class="flex-grow">
                                            <h2 class="text-black mb-4">{{$t('dashboard.amountAccepted')}}</h2>
                                            <p>
                                                <!-- The {{offersAccepted.length}} accepted offers gave the total amount in {{currencies.find( c => c.code == account.locale.currency).name}} of -->
                                                
                                                {{
                                                    $t('dashboard.totalInAcceptedOffersText', {
                                                        acceptedCount: offersAccepted.length,
                                                        currencyName: currencies.find( c => c.code == account.locale.currency).name, 
                                                    }) 
                                                }}
                                            </p>
                                        </div>
                                        <img 
                                            src="/assets/illustrations/undraw_pay_online_b1hk.svg" 
                                            class="flex-none object-contain ml-8 w-32 h-32"
                                        />
                                    </div>
                                    <div class="flex-none flex items-center">
                                        <div class="flex-none flex items-center mr-8">
                                            <strong class="text-blue-900 text-4xl font-bold mr-3">
                                                {{ offerAcceptedAmount }}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                            
                            <div class="panel">
                                <h2 class="text-black">{{$t('dashboard.durationOfVisits')}}</h2>
                                <p class="opacity-75">{{$t('dashboard.durationOfVisitsPerDayInCurrentRange')}}</p>
                                
                                <vue-frappe
                                    id="chart-activity"
                                    :key="'chartStatus:'+startDate+':'+endDate"
                                    :type="'line'"
                                    :labels="chartActivity.labels"
                                    :height="300"
                                    :colors="chartActivity.colors"
                                    :lineOptions="{
                                        hideDots: 1,
                                        regionFill: 1,
                                        spline: 0,
                                    }"
                                    :dataSets="chartActivity.datasets" 
                                    :axisOptions="{
                                        xAxisMode: 'tick' // default: 'span'
                                    }"
                                    :tooltipOptions="{
                                        formatTooltipX: d => d,
                                        formatTooltipY: d => $tc('general.minutes', d),
                                    }"
                                />
                            </div>
                            <div class="panel">
                                <h2 class="text-black">{{$t('dashboard.acceptedAndDeclined')}}</h2>
                                <p class="opacity-75">{{$t('dashboard.offersPerDayInCurrentRange')}}</p>
                                
                                <vue-frappe
                                    id="chart-status"
                                    :key="'chartStatus:'+startDate+':'+endDate"
                                    :type="'bar'"
                                    :labels="chartStatus.labels"
                                    :height="300"
                                    :colors="chartStatus.colors"
                                    :dataSets="chartStatus.datasets" 
                                    :tooltipOptions="{
                                        formatTooltipX: d => d,
                                        formatTooltipY: d => $tc('offers.offersCount', d),
                                    }"
                                    :barOptions="{
                                        stacked: true,
                                        spaceRatio: 0.5,
                                    }"
                                    :axisOptions="{
                                        xAxisMode: 'tick',
                                        xIsSeries: true,
                                    }"
                                />
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    <div 
                        class="slide-up-item col-span-2 2xl:col-span-1"
                        :key="4">
                        
                        <h2 class="mb-4">{{$t('dashboard.latestOfferTrackings')}}</h2>
                        
                        <div class="panel">
                            <div
                                @click="viewOfferLink(track.offer)"
                                class="py-3 mb-2 flex hover:text-black cursor-pointer "
                                v-for="track in trackingsFiltered"
                                :key="track.trackingId">
                                
                                <div class="flex-none mr-8 relative w-12">
                                    <div class="border-r absolute top-0 bottom-0 w-6 -mb-12" />
                                    
                                    <i 
                                        class="absolute top-0 fas w-12 h-12 text-xl bg-gray-300 text-white rounded-full flex items-center justify-center"
                                        :class="
                                            [
                                                track.device == 'mobile' ? 'fa-mobile-alt' : '',
                                                track.device == 'tablet' ? 'fa-tablet-alt' : '',
                                                track.device == 'desktop' || !track.device ? 'fa-desktop' : ''
                                            ]
                                        "
                                    />
                                </div>
                                <div class="flex-grow text-sm leading-tight grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-1">
                                    <div class="flex mb-2">
                                        <div class="w-32">{{$t('dashboard.trackings.customer')}}</div>
                                        <div class="flex-grow truncate">{{track.offer.contact && track.offer.contact.name ? track.offer.contact.name : ''}}</div>
                                    </div>
                                    <div class="flex mb-2">
                                        <div class="w-32">{{$t('dashboard.trackings.when')}}</div>
                                        <div class="flex-grow">{{ track.updatedAt | moment('from') }}</div>
                                    </div>
                                    <div class="flex mb-2">
                                        <div class="w-32">{{$t('dashboard.trackings.totalPrice')}}</div>
                                        <div class="flex-grow">{{currencyFormat(track.offer.priceTotal)}}</div>
                                    </div>
                                    <div class="flex mb-2">
                                        <div class="w-32">{{$t('dashboard.trackings.duration')}}</div>
                                        <div class="flex-grow">{{ (track.visibleSeconds *1000) || 0 | duration('humanize') }}</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="flex w-full items-center justify-center">
                                <div class="rounded-full w-8 h-8 text-center border border-gray-400 flex items-start justify-center text-black cursor-pointer" @click="loadMoreTracking()">...</div>
                            </div>
                            
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        
        
        
        <div 
            v-if="showNotes"
            class="
                fixed inset-0 flex
                
            ">
            <!-- mb-16 
            md:mb-0 -->
            <div 
                @click="showNotes = false"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition 
                v-if="showNotes"
                name="fade">
                
                <Notes 
                    @close="showNotes = false"
                    class="
                        absolute right-0 overflow-hidden
                        md:rounded-l-lg 
                        md:w-150
                    "
                />
            </transition>
        </div>
        <div 
            v-else
            @click="showNotes = true"
            class="
                fixed bottom-0 right-0 bg-yellow-400 w-12 h-12 rounded-full flex items-center justify-center
                mr-4 
                mb-20
                md:mr-8 
                md:mb-8
            ">
            <!-- :class="isMobile ? 'mr-4 mb-20 ' : 'mr-8 mb-8'"> -->
            <!-- v-if="isMobile" -->
            
            <i class="far fa-sticky-note text-2xl text-white" />
        </div>
        
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    // import 'vue2-datepicker/index.css';
    
    export default {
        components: {
            'OfferCard': () => import('@/components/offers/OfferCard.vue'),
            'Notes': () => import('@/components/notes/Notes.vue'),
            DatePicker,
        },
        
        computed:{
            trackings(){
                return this.$store.getters.getTrackings
                    .map( t => {
                        const offerId = t.trackingId.split(':')[0];
                        t.offer = this.offers.find( o => o.offerId == offerId);
                        return t;
                    })
                    .filter( t => t.offer);
            },
            
            trackingsFiltered(){
                return this.trackings.slice(0, this.trackingsCount);
            },
            
            offers(){
                return this.$store.getters.getOffers;
            },
            
            offersAccepted(){
                return this.offers
                    .filter(o => o.status == 'accepted')
                    .filter(o => o.acceptedAt >= new Date(this.startDate).getTime() && o.acceptedAt < new Date(this.endDate).getTime() );
            },
            
            offersDeclined(){
                return this.offers
                    .filter(o => o.status == 'declined')
                    .filter(o => o.declinedAt >= new Date(this.startDate).getTime() && o.declinedAt < new Date(this.endDate).getTime() );
            },
            
            offerAcceptedAmount(){
                const total = this.offers
                    .filter(o => o.acceptedAt >= new Date(this.startDate).getTime() && o.acceptedAt < new Date(this.endDate).getTime() )
                    .reduce( (sum, o) => {
                        return parseFloat(o.priceTotal) + sum;
                    }, 0);
                
                return this.currencyFormat( Math.round(total) );
            },
            
            messages(){
                return this.$store.getters.getMessages;
            },
            
            messagesSorted(){
                return this.messages.filter( m => !m.readAt && m.from != 'support')
            },
            
            uniqueOffersWithMessages(){
                const offers = [];
                
                this.messagesSorted.forEach( (message, i) => {
                    const offerId = message.messageId.split(':')[0];
                    
                    if ( !offers.find( o => o.offerId == offerId) ) {
                        offers.push( this.offers.find( o => o.offerId == offerId ) );
                    }
                });
                
                return offers;
            },
            
            offersPassedFollowupDate(){
                return this.offers.filter(o => {
                    if ( 
                        o.followedupAt && 
                        o.status == 'active') {
                        
                        return true
                    }
                });
            },
            
            offersPassedHibernation(){
                const now = new Date().getTime();
                
                return this.offers.filter(o => {
                    if ( 
                        o.status == 'active' && 
                        this.$moment.duration( this.$moment(now).diff( this.$moment(o.updatedAt)) ).asDays() > this.account.hibernate.days) {
                        
                        return true
                    }
                });
            },
            
            offerActiveAmount(){
                const total = this.offers
                    .filter(o => {
                        if ( o.status == 'active') {
                            return true
                        }
                    })
                    .reduce( (sum, o) => {
                        if ( o.priceTotal === null || isNaN(o.priceTotal) ) {
                            // console.log('check price total NaN: ', o);
                            return sum
                        }
                        
                        return parseFloat(o.priceTotal) + sum;
                    }, 0);
                
                return this.currencyFormat( Math.round(total) );
            },
            
            chartActivity(){
                let activity = {
                    colors: ['#259BD7'],
                };
                
                const startDate = new Date( this.startDate );
                const endDate = new Date( this.endDate );
                
                activity.labels = this.getDaysArray( startDate, endDate).map( (v) => v.getDate() );
                activity.datasets = [
                    {
                        name: '', 
                        values: this.getDaysArray( startDate, endDate).map( from => {
                            const to = new Date( from );
                            to.setDate( to.getDate() + 1 );
                            
                            const totalSeconds = this.trackings.reduce( (sum, track) => {
                                if ( 
                                    track.visibleSeconds &&
                                    track.updatedAt >= from.getTime() && 
                                    track.updatedAt < to.getTime() ){
                                    
                                    return parseInt(track.visibleSeconds) + sum;
                                }
                                
                                return sum;
                            }, 0);
                            
                            return Math.round( this.$moment.duration( totalSeconds, 'seconds' ).asMinutes() );
                        }),
                    },
                ];
                
                return activity;
            },
            
            chartStatus(){
                let status = {
                    colors: ['#2EB2E0', '#f56565'],
                    // colors: ['#2EB2E0', '#218FD2'],
                };
                
                const startDate = new Date( this.startDate );
                const endDate = new Date( this.endDate );
                
                status.labels = this.getDaysArray( startDate, endDate).map( (v) => v.getDate() );
                status.datasets = [
                    {
                        name: 'Accepted', 
                        values: this.getDaysArray( startDate, endDate).map( from => {
                            const to = new Date( from );
                            to.setDate( to.getDate() + 1 );
                            
                            const total = this.offers.reduce( (sum, offer) => {
                                if ( 
                                    offer.acceptedAt &&
                                    offer.acceptedAt >= from.getTime() && 
                                    offer.acceptedAt < to.getTime() ){
                                    
                                    return 1 + sum;
                                }
                                
                                return sum;
                            }, 0);
                            
                            return total;
                        }),
                    },
                    {
                        name: 'Declined', 
                        values: this.getDaysArray( startDate, endDate).map( from => {
                            const to = new Date( from );
                            to.setDate( to.getDate() + 1 );
                            
                            const total = this.offers.reduce( (sum, offer) => {
                                if ( 
                                    offer.declinedAt &&
                                    offer.declinedAt >= from.getTime() && 
                                    offer.declinedAt < to.getTime() ){
                                    
                                    return 1 + sum;
                                }
                                
                                return sum;
                            }, 0);
                            
                            return total;
                        }),
                    },
                ];
                return status;
            },
            
            startDate(){
                return this.dateRange ? this.dateRange[0] : null;
            },
            
            endDate(){
                return this.dateRange ? this.dateRange[1] : null;
            },
        },
        
        data(){
            return {
                showCurrent: false,
                showDateRange: false,
                showCalendar: false,
                currentOffers: null,
                refreshing: null,
                dateRange: null,
                preset: null,
                showNotes: false,
                trackingsCount: 10,
            }
        },
        
        methods: {
            loadMoreTracking(){
                this.trackingsCount = this.trackingsCount + 10;
            },
            
            scrollToOffersList(){
                this.$nextTick( () => {
                    var element = document.getElementById('offersList');
                    if (element) {
                        try {
                            element.scrollIntoView({behavior: 'smooth',inline: 'nearest'});
                        } 
                        catch (e) {
                            console.error(e);
                        }
                    }
                });
            },
            
            getDaysArray(start, end) {
                for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
                    arr.push(new Date(dt));
                }
                return arr;
            },
            
            viewOfferLink( offer ){
                this.$router.push({ name: 'offers-show', params: { offerId: offer.offerId } });
            },
            
            selectDateRangePreset( preset ) {
                let start;
                let end;
                
                switch (preset) {
                    case 'last7days':
                        end = this.$moment().add(1, 'day').toDate();
                        start = this.$moment( end ).subtract(1, 'week').toDate();
                        break;
                    case 'lastTwoWeeks':
                        end = this.$moment().add(1, 'day').toDate();
                        start = this.$moment( end ).subtract(2, 'weeks').toDate();
                        break;
                    case 'last3Months':
                        end = this.$moment().add(1, 'day').toDate();
                        start = this.$moment( end ).subtract(3, 'months').toDate();
                        break;
                    case 'last30days':
                    default:
                        end = this.$moment().add(1, 'day').toDate();
                        start = this.$moment( end ).subtract(1, 'months').toDate();
                        break;
                }
                
                this.preset = preset; 
                
                this.dateRange = [
                    start,
                    end,
                ];
            },
        },
        
        mounted(){
            this.selectDateRangePreset( 'lastTwoWeeks' );
            
            // const endDate = this.$moment().add(1, 'day').format('YYYY-MM-DD');
            // const startDate = this.$moment( endDate ).subtract(2, 'weeks').format('YYYY-MM-DD');
            // 
            // this.dateRange = [
            //     startDate,
            //     endDate,
            // ];
            
            this.showCurrent = true;
            this.showDateRange = true;
        },
    }
</script>
